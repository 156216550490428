<template>
  <div class="customer-portal-header">
    <div v-if="!props.isMyPortal" class="header-path">
      <span class="path-folder" v-for="(folder, index) in filePath" :key="index">
        <button type="button" class="path-folder-button" @click="gotoFolder({folder, index})">
          <span>{{ folder }}</span>
        </button>
      </span>
    </div>
    <div v-else class="header-path">
    </div>
    <div class="header-buttons-search">
      <div class="header-buttons">
        <div class="button">
          <button type="button"
            class="header-buttons-button btn btn-outline-primary btn-md"
            :class="[{'header-buttons-button-disabled': !someItemsSelected && !someProjectsSelected && currentView === 'Lists'}]"
            @click="toggleSelecting"
          >
            <icon id="icon" :icon="someItemsSelected || someProjectsSelected || selecting || selectingProjects ? 'times' : 'check'" />
          </button>
          <i :class="[currentlyHoveringTooltip === 'xmark' || currentlyHoveringTooltip === 'check' ? 'tooltips' : 'tooltips-transparent']">
            <i v-if="currentlyHoveringTooltip === 'xmark'">Unselect</i><i v-else>Select</i>
          </i>
        </div>
        <div class="button">
          <button
            v-show="isAdmin"
            class="header-buttons-button btn btn-outline-success btn-md"
            :class="[{'header-buttons-button-disabled': someItemsSelected}]"
            @click="createNewFolder"
          >
            <icon id="icon" icon="folder-plus" />
          </button>
          <i :class="[currentlyHoveringTooltip === 'folder-plus' ? 'tooltips' : 'tooltips-transparent']">New Folder</i>
        </div>
        <div class="button">
          <button
            v-show="isAdmin"
            class="header-buttons-button btn btn-outline-primary btn-md"
            :class="[{'header-buttons-button-disabled': someItemsSelected}]"
            @click="uploadFiles"
          >
            <icon id="icon" icon="file-upload" />
          </button>
          <i :class="[currentlyHoveringTooltip === 'file-upload' ? 'tooltips' : 'tooltips-transparent']">Upload</i>
        </div>
        <div class="button">
          <button
            class="header-buttons-button btn btn-outline-info btn-md"
            :class="[{'header-buttons-button-disabled': !someItemsSelected && !someProjectsSelected}]"
            @click="startDownloading"
          >
            <icon id="icon" icon="download" />
          </button>
          <i :class="[currentlyHoveringTooltip === 'download' ? 'tooltips' : 'tooltips-transparent']">Download</i>
        </div>
        <div class="button">
          <button
            v-show="isAdmin"
            class="header-buttons-button btn btn-outline-warning btn-md"
            :class="[{'header-buttons-button-disabled': !someItemsSelected}]"
            @click="startMoving"
          >
            <icon id="icon" icon="directions" />
          </button>
          <i :class="[currentlyHoveringTooltip === 'directions' ? 'tooltips' : 'tooltips-transparent']">Move files</i>
        </div>
        <div class="button">
          <button
            class="header-buttons-button btn btn-outline-danger btn-md"
            :class="[{'header-buttons-button-disabled': !someItemsSelected && !someProjectsSelected}]"
            @click="deleteFile"
          >
            <icon id="icon" icon="trash" />
          </button>
          <i :class="[currentlyHoveringTooltip === 'trash' ? 'tooltips' : 'tooltips-transparent']">Delete</i>
        </div>
      </div>
      <div class="header-search-bar">
        <input type="text" class="search-bar" placeholder="Search..." v-model="search" @change="updateSearch" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const props = defineProps({
  isMyPortal: Boolean
})
const store = useStore();
const router = useRouter();
const search = ref("");
const input = ref(null);
const currentlyHoveringTooltip = ref(null);

// states
const currentView = computed(() => store.state.customerPortal.currentView);
const selecting = computed(() => store.state.customerPortal.selecting);
const selectingProjects = computed(() => store.state.projects.selecting);
const isAdmin = computed(() => store.state.customerPortal.isAdmin);
const isBannerUser = computed(() => store.getters["user/isBannerUser"]);
const currentPath = computed(() => store.state.customerPortal.currentPath);
const rootPath = computed(() => store.state.customerPortal.rootPath);
// getters
const isItMyPortal = computed(() => store.getters["customerPortal/getIsMyPortal"]);
const filePath = computed(() => store.getters["customerPortal/filePath"]);
const someItemsSelected = computed(() => store.getters["customerPortal/someItemsSelected"]);
const someProjectsSelected = computed(() => store.getters["projects/someItemsSelected"]);
// methods
const gotoFolder = async context => {
  let path = currentPath.value.split("/");
  if (isAdmin.value) {
    path = path.slice(0, context.index + 1).join("/") + "/";
    router.push(`/admin/customer-portal/${path.slice(rootPath.value.length)}`);
  } else {
    path = path.slice(0, context.index + 2).join("/") + "/";
    router.push(`/customer-portal/${path.slice(rootPath.value.split("/")[0].length + 1)}`);
  }
  store.commit("customerPortal/SET_CURRENT_PAGE", 1);
  await store.dispatch("customerPortal/getItems", { folderName: path })
}
const startMoving = () => {
  store.commit("customerPortal/SET_MOVING", true);
}
const startDownloading = () => {
  if (!isItMyPortal.value) {
    store.dispatch("customerPortal/downloadItems");
    return;
  }
  if (isBannerUser.value) {
    store.commit("modal/SET_NOTIFICATION_MESSAGE",
        `Your project is available to download in <a href="${process.env.VUE_APP_BANNER_DOMAIN}/dashboard/content" target="_blank">Banner</a> (Dashboard -> Content Library).<br>`)
    store.dispatch("modal/showModal", {
      modalTitle: "CMS Banner User",
      modalName: "NotificationModal"
    })
    return;
  }
  store.dispatch("projects/downloadProjects");
}
const toggleSelecting = () => {
  if (!isItMyPortal.value) {
    store.commit("customerPortal/SET_SELECTING", !selecting.value);
  } else {
    store.commit("projects/SET_SELECTING", !selectingProjects.value);
    store.commit("projects/SET_SELECTED_PROJECTS", {});
  }
  currentlyHoveringTooltip.value = currentlyHoveringTooltip.value === "xmark" ? "check" : "xmark"
  if (currentView.value === "Lists") {
    store.dispatch("customerPortal/unselectAndCancel");
    store.dispatch("projects/unselectAndCancel");
  }
  if (currentView.value === "Large Icons") {
    store.commit("customerPortal/SET_SELECTED_ITEMS", {});
  }
}
const createNewFolder = () => {
  store.dispatch("modal/showModal", {
    modalTitle: "New Folder",
    modalName: "CustomerPortalNewFolder"
  })
}
const updateSearch = () => {
  if (!isItMyPortal.value) {
    store.commit("customerPortal/SET_SEARCH", search.value);
    return;
  }
  store.commit("projects/SET_SEARCH", search.value);
}
const uploadFiles = () => {
  input.value.click();
}
const deleteFile = () => {
  if (!isItMyPortal.value) {
    store.dispatch("customerPortal/deleteItems");
    return;
  }
  if (isBannerUser.value) {
    store.commit("modal/SET_NOTIFICATION_MESSAGE",
        `Your project will still be available on <a href="${process.env.VUE_APP_BANNER_DOMAIN}/dashboard/content" target="_blank">Banner</a> (Dashboard -> Content Library).<br>`
    );
    store.dispatch("modal/showModal", {
      modalTitle: "Banner User, Are you sure?",
      modalName: "ConfirmationModal"
    })
  }
  store.dispatch("projects/deleteProjects");
}

watch(
  () => isItMyPortal.value,
  () => {
    search.value = "";
  }
)

onMounted(async () => {
  input.value = document.createElement("input");
  input.value.type = "file";
  input.value.setAttribute("multiple", true);
  input.value.addEventListener("change", async (e) => {
    let items = e.target.files;
    let files = {};
    let totalSize = 0;
    for (const item of items) {
      if (!files[currentPath.value]) {
          files[currentPath.value] = [];
      }
      files[currentPath.value].push({
          file: item,
          size: item.size
      });
      totalSize += item.size;
    }
    store.commit("customerPortal/SET_UP_DOWNLOADING_SIZE", totalSize);
    await store.dispatch("customerPortal/uploadItems", files);
    input.value.value = "";
  });
  const icons = document.getElementsByClassName("header-buttons-button");
  for (let i=0 ;i<icons.length; i++) {
    icons[i].addEventListener("mouseenter", e => {
      currentlyHoveringTooltip.value = e.target.firstChild.attributes['data-icon'].nodeValue;
    })
    icons[i].addEventListener("mouseleave", () => {
      currentlyHoveringTooltip.value = null;
    })
  }
});



</script>

<style scoped lang="scss">
.customer-portal-header {
  display: flex;
  justify-content: space-between;
}
.path-folder-button {
  font-size: 20px;
  border: none;
  background-color: white;
}
.path-folder {
  background-color: transparent;
}
.path-folder::after {
  content: ">";
}
.header-buttons {
  display: flex;
}
.header-buttons-search {
  display: flex;
  align-items: center;
}
.header-search-bar {
  margin: 0 0 0 4ch;
}
.search-bar {
  border: none;
  border-bottom: 1px solid lightgray;
  height: 4ch;
  width: 35ch;
  &:focus {
    outline: none;
  }
}
.header-buttons-button {
  margin: 0 5px;
  border: none;
}
.header-buttons-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
::placeholder {
  color: grey;
}
.button {
  display: flex;
  flex-direction: column;
}
.tooltips-transparent {
  position: absolute;
  margin-top: 1.5em;
  font-size: 5px;
  opacity: 0;
}
.tooltips {
  position: absolute;
  margin-top: 3.5em;
  margin-left: 1em;
  font-size: 10px;
}
</style>