import axios from "axios";

const state = {
  templates: [],
  categoryCount: {},
  keywordCount: {},
  currentVideo: {
    aep: "",
    categories: [],
    custom_fields: {
      colorFields: [],
      imageFields: [],
      palettes: [],
      textFields: []
    },
    date_added: "",
    description: "",
    duration: 0,
    frame_number: 0,
    id: 0,
    image_count: 0,
    json: "",
    json_id: "",
    keywords: [],
    name: "",
    s3_id: "",
    status: "",
    stock_s3_id: "",
    text_field_count: 0,
  },
  freeTemplateIds: [],
  bespokeTemplateIds: [],
  categoryFilters: {},
  classFilters: ["free", "customizable", "bespoke", "stock"],
  logoImageFilter: false,
  minTextFieldsLimit: 0,
  maxTextFieldsLimit: 12,
  templateSearch: "",
  totalNumberObjects: {
    "free": 0,
    "customizable": 0,
    "bespoke": 0,
    "stock": 0
  },
  loadMoreObjects: {
    "free": 6,
    "customizable": 6,
    "bespoke": 6,
    "stock": 6
  }
}

const getters = {
  getAllTemplates: state => {
    let temp = [];
    if (Object.keys(state.categoryFilters).length === 0) {
      temp = [...state.templates];
    } else {
      state.templates.forEach(template => {
        for (let i=0; i<template.categories.length; i++) {
          if (state.categoryFilters[template.categories[i]] === 0) {
            temp.push(template);
            break;
          }
        }
      })
    }
    if (state.logoImageFilter) {
      temp = temp.filter(t => t.image_count > 0);
    }
    temp = temp.filter(t => t.text_field_count >= state.minTextFieldsLimit && t.text_field_count <= state.maxTextFieldsLimit);
    if (state.templateSearch !== '') {
      return temp.filter(t => {
        return t.name.toLowerCase().includes(state.templateSearch.toLowerCase());
      })
    }
    return temp;
  },
  getCustomizableTemplates: (state, getters) => {
    let templates = [];
    getters.getAllTemplates.forEach(template => {
      if (!state.freeTemplateIds.includes(template.id) && !state.bespokeTemplateIds.includes(template.id)) {
        templates.push(template);
      }
    })
    return templates.slice(0, state.loadMoreObjects["customizable"]);
  },
  getFreeTemplates: (state, getters) => {
    return getters.getAllTemplates.filter(template => {
      return state.freeTemplateIds.includes(template.id);
    }).slice(0, state.loadMoreObjects["free"]);
  },
  getFreeTemplateIds: state => {
    return state.freeTemplateIds;
  },
  getBespokeTemplates: (state, getters) => {
    return getters.getAllTemplates.filter(template => {
      return state.bespokeTemplateIds.includes(template.id);
    }).slice(0, state.loadMoreObjects["bespoke"]);
  },
  getAllTemplatesExceptFree: (state, getters) => {
    return getters.getAllTemplates.filter(template => {
      return !state.freeTemplateIds.includes(template.id);
    })
  },
  getAllTemplatesExceptPopular: (state, getters, rootState) => {
    return getters.getAllTemplates.filter(template => {
      return !rootState.adminPopularSeasonalVideos.popularVideoIds.includes(template.id);
    })
  },
  getAllTemplatesExceptSeasonal: (state, getters, rootState) => {
    return getters.getAllTemplates.filter(template => {
      return !rootState.adminPopularSeasonalVideos.seasonalVideoIds.includes(template.id);
    })
  },
  getAllTemplatesExceptBespoke: (state, getters) => {
    return getters.getAllTemplates.filter(template => {
      return !state.bespokeTemplateIds.includes(template.id);
    })
  },
  getLoadMoreObjects: state => {
    return state.loadMoreObjects;
  },
  getTotalNumberObjects: state => {
    return state.totalNumberObjects;
  },
  getCurrentVideo: state => {
    return state.currentVideo;
  },
  getYouMightAlsoLikeVideos: state => {
    let templates = [];
    for (let i=0; i<state.templates.length; i++) {
      for (let j=0; j<state.templates[i].categories.length; j++) {
        if (state.currentVideo.categories.includes(state.templates[i].categories[j]) &&
        state.templates[i].id !== state.currentVideo.id) {
          templates.push(state.templates[i]);
          break;
        }
      }
      if (templates.length > 7) {
        break;
      }
    }
    return templates;
  },
  getCategoryFilters: state => {
    return state.categoryFilters;
  },
  getTemplateSearch: state => {
    return state.templateSearch;
  },
  currentTemplateIsFree: state => {
    return state.freeTemplateIds.includes(state.currentVideo.id);
  },
  getAspectRatio: state => index => {
    return parseFloat(state.currentVideo.custom_fields.imageFields[index].aspectRatio);
  }
}

const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  SET_FREE_TEMPLATE_ID(state, templates) {
    state.freeTemplateIds = templates;
  },
  SET_BESPOKE_TEMPLATE_IDS(state, templates) {
    state.bespokeTemplateIds = templates;
  },
  SET_CATEGORY_COUNT_FROM_TEMPLATE(state, category_count) {
    state.categoryCount = category_count;
  },
  SET_KEYWORD_COUNT_FROM_TEMPLATE(state, keyword_count) {
    state.keywordCount = keyword_count;
  },
  SET_CURRENT_VIDEO(state, video) {
    state.currentVideo = video;
  },
  SET_LOAD_MORE_OBJECTS(state, context) {
    state.loadMoreObjects[context.name] = context.number;
  },
  SET_TOTAL_NUMBER_OBJECT(state, context) {
    state.totalNumberObjects[context.name] = context.number;
  },
  SET_CATEGORY_FILTERS(state, filters) {
    state.categoryFilters = filters;
  },
  SET_TEMPLATE_SEARCH(state, search) {
    state.templateSearch = search;
  },
  SET_LOGO_IMAGE_FILTER(state, boolean) {
    state.logoImageFilter = boolean;
  },
  SET_TEXT_FIELDS_LIMIT(state, context) {
    if (context.field === 'min') {
      if (context.limit > state.maxTextFieldsLimit) {
        return;
      }
      state.minTextFieldsLimit = context.limit;
    } else {
      if (state.minTextFieldsLimit > context.limit) {
        return;
      }
      state.maxTextFieldsLimit = context.limit;
    }
    
  }
}

const actions = {
  async getTemplates({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/templates`, {
      withCredentials: true
    });
    commit("SET_TEMPLATES", response.data.templates);
    commit("SET_FREE_TEMPLATE_ID", response.data.freeTemplateIds);
    commit("SET_BESPOKE_TEMPLATE_IDS", response.data.bespokeTemplateIds);
    commit("SET_TOTAL_NUMBER_OBJECT", { name: "free", number: response.data.freeTemplateIds.length});
    commit("SET_TOTAL_NUMBER_OBJECT", { name: "bespoke", number: response.data.bespokeTemplateIds.length});
    commit("SET_TOTAL_NUMBER_OBJECT", { 
      name: "customizable", 
      number: response.data.templates.length - response.data.freeTemplateIds.length - response.data.bespokeTemplateIds.length});
    await dispatch("getCategoryAndKeyword");
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async getCategoryAndKeyword({ state, commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    let categories = {}, keywords = {};
    state.templates.forEach(item => {
      item.categories.forEach(category => {
        categories[category] = categories[category] + 1 || 1;
      });
      item.keywords.forEach(keyword => {
        keywords[keyword] = keywords[keyword] + 1 || 1;
      });
    });
    commit("SET_CATEGORY_COUNT_FROM_TEMPLATE", categories);
    commit("SET_KEYWORD_COUNT_FROM_TEMPLATE", keywords);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async setCurrentVideoById({ rootState, state, commit, dispatch }, id) {
    dispatch("alert/startLoading", {}, { root: true });
    let idIsTemplate;
    for (let i=0; i<state.templates.length; i++) {
      if (state.templates[i].id === +id) {
        idIsTemplate = true;
        commit("SET_CURRENT_VIDEO", state.templates[i]);
        break;
      }
    }
    if (!idIsTemplate) {
      for (let i=0; i<rootState.stocks.stocks.length; i++) {
        if (rootState.stocks.stocks[i].id === +id) {
          commit("SET_CURRENT_VIDEO", rootState.stocks.stocks[i]);
          break;
        }
      }
    }
    dispatch("alert/stopLoading", {}, { root: true });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}