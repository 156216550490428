<template>
  <div id="app" :class="[loading ? 'loading': '']">
    <div class="alert-component"><AlertComponent /></div>
    <LoadingSVG v-if="loading" />
    <div class="my-modal" v-if="modalActive">
      <ModalComponent />
    </div>
    <div class="webpage">
      <div class="header" v-if="!selfPageList.includes(currentRoute)">
        <NavbarComponent />
      </div>
      <div :class="[modalActive ? 'page-wrap-modal-active' : 'page-wrap']">
        <router-view />
      </div>
      <div class="footer" v-if="!selfPageList.includes(currentRoute)">
        <FooterComponent />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import AlertComponent from "@/components/AlertComponent.vue";
import LoadingSVG from "@/components/LoadingSVG.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

const router = useRouter();
const store = useStore();

const currentRoute = computed(() => router.currentRoute.value.name);
const selfPageList = ["login", "signup", "maintenance"];

// states
const loading = computed(() => store.state.alert.loading);
const modalActive = computed(() => store.state.modal.active);
// getters
const templates = computed(() => store.getters["templates/getAllTemplates"]);
const stocks = computed(() => store.getters["stocks/getAllStocks"]);
const maintenanceActive = computed(() => store.getters["maintenance/maintenanceActive"]);
const isLoggedIn = computed(() => store.getters["user/isLoggedIn"]);

// created


if (templates.value.length === 0) {
  store.dispatch("templates/getTemplates");
}
if (stocks.value.length === 0) {
  store.dispatch("stocks/getStocks")
}

onBeforeMount(async () => {
  await store.dispatch("user/getUser");
  if (router.currentRoute.value.name === "new-password" && !isLoggedIn.value) {
    await store.dispatch("modal/showModal", {
      modalTitle: "Set New Password",
      modalName: "NewPasswordModal"
    })
  }
})

onMounted( async () => {
  await store.dispatch("maintenance/getMaintenance");
  if (maintenanceActive.value && router.currentRoute.value.name === "home") {
    await store.dispatch("modal/showModal", {
      modalTitle: "Maintenance",
      modalName: "MaintenanceModal"
    })
  }
})
</script>

<style lang="scss">
#app {
  text-align: center;
}
.alert-component {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.webpage {
  display: flex;
  flex-direction: column;
}
.page-wrap-modal-active {
  opacity: 0.2;
  pointer-events: none;
  min-height: 80vh;
}
.page-wrap {
  min-height: 80vh;
}
.my-modal {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 30%;
  border-radius: 20px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}
.footer {
  height: 150px;
}
</style>
