<template>
  <div class="container-fluid">
    <div class="message">
      <p id="message"></p>
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button"
        @click="closeModal">Cancel</button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const notificationMessage = computed(() => store.getters["modal/getNotificationMessage"]);
const modalTitle = computed(() => store.getters["modal/getModalTitle"]);

const closeModal = () => {
  if (modalTitle.value === "Attention Banner User!") {
    router.push("/");
  }
  if (modalTitle.value === "Updating Subscription") {
    router.push("/library");
  }
  store.dispatch("modal/closeModal");
}

onMounted(() => {
  let msg = document.getElementById("message");
  msg.innerText = notificationMessage.value;
})

</script>

<style scoped lang="scss">
.message {
  text-align: left;
}
.buttons {
  #cancel-button {
    margin: 1em;
    width: 30%;
    &:hover {
      background-color: var(--ce-cancel-button-color);
    }
  }
}
</style>