import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import HomeView from "@/views/HomeView";
import AdminView from "@/views/AdminView";
import SigninView from "@/views/SigninView";
import PageNotFoundView from "@/views/PageNotFoundView";
import Maintenance from "@/views/MaintenanceView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance
  },
  {
    path: "/login",
    name: "login",
    component: SigninView
  },
  {
    path: "/signup",
    name: "signup",
    component: SigninView
  },
  {
    path: "/admin/updatetemplate",
    name: "ManageTemplate",
    component: () => import("../views/UpdateTemplateView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/admin/managetemplate",
    name: "ManageTemplateTest",
    component: () => import("../views/ManageTemplatesView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/admin/managestock",
    name: "adminManageStock",
    component: () => import("../views/UpdateStockView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/account/:tab",
    name: "account",
    component: () => import("../views/AccountView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/admin/users/:userId",
    name: "userDetail",
    component: () => import("../views/UserDetailView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/admin/:tab*",
    name: "admin",
    component: AdminView,
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/confirm-user/:username/:confirmationCode",
    name: "confirm-user",
    component: () => import("../views/LibraryView.vue"),
    beforeEnter: async (to, from, next) => {
      await store.dispatch("user/confirmUser", {
        username: to.params.username,
        confirmationCode: to.params.confirmationCode
      })
      next("/library");
    }
  },
  {
    path: "/new-password/:confirmationCode",
    name: "new-password",
    component: HomeView
  },
  {
    path: "/download-from-email/:projectId/:fileName",
    name: "download-from-email",
    component: () => import("../views/DownloadProject.vue")
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/PricingView.vue")
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/CheckoutView.vue")
  },
  {
    path: "/library",
    name: "library",
    component: () => import("../views/LibraryView.vue")
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue")
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/TermsView.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue")
  },
  {
    path: "/video/:id",
    name: "video",
    component: () => import("../views/VideoDetailView.vue"),
    beforeEnter: async () => {
      store.commit("alert/SET_GOTO_AFTER_LOGIN_ROUTER", "/");
    }
  },
  {
    path: "/wizard/:step",
    name: "wizard",
    component: () => import("../views/WizardView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id || store.state.templates.freeTemplateIds.includes(store.state.templates.currentVideo.id)) {
          next();
        } else {
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/customer-portal/:username/:directory*",
    name: "customerPortal",
    component: () => import("../views/CustomerPortalView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.state.user.currentUser.id) {
        await store.dispatch("user/getUser");
        if (store.state.user.currentUser.id) {
          next();
        } else {
          store.dispatch("alert/getAlert", {
            alert: "danger",
            message: "Your session has timed out. Please log in again."
          })
          next("/login");
        }
      } else {
        next();
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFoundView
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
