<template>
  <div class="customer-portal-sidebar">
    <div class="sidebar-filter-list">
      <p id="font">Filter:</p>
      <span class="filter-list" v-for="(filter, index) in filterList" :key="index">
        <button class="sidebar-button" 
          :class="{'sidebar-button-selected': filterSelected(filter.name.toLowerCase().slice(0, -1))}" 
          @click="toggleFilter(filter.name)">
          <icon :icon="filter.icon" /> {{ filter.name }}
        </button>
      </span>
    </div>
    <hr />
    <div class="sidebar-views">
      <p id="font">Views:</p>
      <span class="views" v-for="(view, index) in viewList" :key="index">
        <button class="sidebar-button" :class="{'sidebar-button-selected': currentView === view.name}"
          @click="setCurrentView(view.name)">
          <icon :icon="view.icon" /> {{ view.name }}
        </button>
      </span>
    </div>
    <hr />
    <div class="sidebar-items-per-page">
      <p id="font">Items per page:</p>
      <span class="items-per-page" v-for="(itemsPerPage, index) in itemsPerPageList" :key="index">
        <button class="sidebar-button" 
          :class="{'sidebar-button-selected': getItemsPerPage === itemsPerPage.name}" 
          @click="storeSetItemsPerPage(itemsPerPage.name)">
          {{ itemsPerPage.name }}
        </button>
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  isMyPortal: Boolean
})

const filterList = ref([
  {icon: "images", name: "Images", selected: false, index: 1},
  {icon: "video", name: "Videos", selected: false, index: 2},
  {icon: "file", name: "Others", selected: false, index: 3}
])

if (!props.isMyPortal) {
  filterList.value = [
    {icon: "folder", name: "Folders", selected: false, index: 0},
    {icon: "images", name: "Images", selected: false, index: 1},
    {icon: "video", name: "Videos", selected: false, index: 2},
    {icon: "file", name: "Others", selected: false, index: 3}
  ]
}

const viewList = ref([
  {icon: "image", name: "Large Icons"},
  {icon: "list", name: "Lists"}
])
// states
const itemsPerPageList = computed(() => store.state.customerPortal.itemsPerPageList);
const storeItemsPerPage = computed(() => store.state.customerPortal.itemsPerPage);
const itemsPerPageProjects = computed(() => store.state.projects.itemsPerPage);
const currentFilters = computed(() => store.state.customerPortal.currentFilters);
const currentProjectFilters = computed(() => store.state.projects.currentFilters);
const currentView = computed(() => store.state.customerPortal.currentView);
const isItMyPortal = computed(() => store.getters["customerPortal/getIsMyPortal"]);
// mutations
const storeSetItemsPerPage = number => {
  if (!isItMyPortal.value) {
    store.commit("customerPortal/SET_ITEMS_PER_PAGE", number);
    store.commit("customerPortal/SET_CURRENT_PAGE", 1);
    return;
  }
  store.commit("projects/SET_ITEMS_PER_PAGE", number);
  store.commit("projects/SET_CURRENT_PAGE", 1);
  
}
const setCurrentView = view => {
  store.commit("customerPortal/SET_CURRENT_VIEW", view);
}
const toggleFilter = filter => {
  let temp = filter;
  if (temp === "Others") {
    temp = "Files";
  }
  if (!isItMyPortal.value) {
    store.commit("customerPortal/TOGGLE_CURRENT_FILTERS", temp);
    return;
  }
  store.commit("projects/TOGGLE_CURRENT_FILTERS", temp);
}
const filterSelected = filter => {
  let temp = filter;
  if (temp === "other") {
    temp = "file";
  }
  if (!isItMyPortal.value) {
    return currentFilters.value.includes(temp);
  }
  return currentProjectFilters.value.includes(temp);
}
const getItemsPerPage = computed(() => {
  if (!isItMyPortal.value) {
    return storeItemsPerPage.value;
  }
  return itemsPerPageProjects.value;
})

watch(
  () => props.isMyPortal,
  (myPortal) => {
    if (!myPortal) {
      filterList.value = [
        {icon: "folder", name: "Folders", selected: false, index: 0},
        {icon: "images", name: "Images", selected: false, index: 1},
        {icon: "video", name: "Videos", selected: false, index: 2},
        {icon: "file", name: "Others", selected: false, index: 3}
      ]
    } else {
      filterList.value = [
        {icon: "images", name: "Images", selected: false, index: 1},
        {icon: "video", name: "Videos", selected: false, index: 2},
        {icon: "file", name: "Others", selected: false, index: 3}
      ]
    }
  }
)
</script>







<style scoped lang="scss">
#font {
  font-size: 13px;
}
#label {
  margin-left: 5px;
}
.sidebar-back-button {
  cursor: pointer;
}
.sidebar-button {
  width: 100%;
  border: none;
  border-radius: 50px;
  background-color: white;
  margin: 3px 0;
  &:hover {
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
}
.sidebar-button-selected {
  color: white;
  background-color: rgb(0, 0, 0);
}
.sidebar-filter-list {
  display: grid;
  margin: 3em 0;
}
.filter-list {
  font-family: monospace;
  height: fit-content;
}
.items-per-page {
  text-align: left;
}
.sidebar-items-per-page {
  display: grid;
}
</style>