<template>
  <div class="my-modal-content">
    <div class="my-modal-head">
      <h4>{{ modalTitle }}</h4>
    </div>
    <div class="my-modal-body">
      <hr />
      <component :is="modalPair[modalName]" />
    </div>
  </div>
</template>

<script setup>
import CustomerPortalFileDetail from "@/components/Modal/CustomerPortal/FileDetail.vue";
import CustomerPortalNewFolder from "@/components/Modal/CustomerPortal/NewFolder.vue";
import AddDisplay from "@/components/Modal/AddDisplay.vue";
import PriceInfo from "@/components/Modal/PriceInfo.vue";
import NotificationModal from "@/components/Modal/NotificationModal.vue";
import ConfirmationModal from "@/components/Modal/ConfirmationModal.vue";
import UpdateStatus from "@/components/Modal/Admin/UpdateStatus.vue";
import UpdateCategoryKeyword from "@/components/Modal/Admin/UpdateCategoryKeyword.vue";
import PaymentMethodModal from "@/components/Modal/PaymentMethodModal.vue";
import ForgotPasswordModal from "@/components/Modal/ForgotPasswordModal.vue";
import EmailModal from "@/components/Modal/EmailModal.vue";
import UserProjectDetailModal from "@/components/Modal/Admin/UserProjectDetailModal.vue";
import MaintenanceModal from "@/components/Modal/Admin/MaintenanceModal.vue";
import MagicinfoLoginModal from "@/components/Modal/MagicinfoLoginModal.vue";
import CanvasUpload from "@/components/Modal/CanvasUpload.vue";
import NewPasswordModal from "@/components/Modal/NewPasswordModal.vue";
import { computed } from "vue";
import { useStore } from "vuex";

const modalPair = {
  "CustomerPortalFileDetail": CustomerPortalFileDetail,
  "CustomerPortalNewFolder": CustomerPortalNewFolder,
  "AddDisplay": AddDisplay,
  "PriceInfo": PriceInfo,
  "NotificationModal": NotificationModal,
  "ConfirmationModal": ConfirmationModal,
  "UpdateStatus": UpdateStatus,
  "UpdateCategoryKeyword": UpdateCategoryKeyword,
  "PaymentMethodModal": PaymentMethodModal,
  "ForgotPasswordModal": ForgotPasswordModal,
  "EmailModal": EmailModal,
  "UserProjectDetailModal": UserProjectDetailModal,
  "MaintenanceModal": MaintenanceModal,
  "MagicinfoLoginModal": MagicinfoLoginModal,
  "CanvasUploadModal": CanvasUpload,
  "NewPasswordModal": NewPasswordModal
}

const store = useStore();

// states
const modalTitle = computed(() => store.state.modal.modalTitle);
const modalName = computed(() => store.state.modal.modalName);

</script>

<style scoped lang="scss">
.my-modal-content {
}
.my-modal-head {
  display: flex;
  margin: 2ex auto auto 2ex;
}
</style>